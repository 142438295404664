
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { localizeGender } from '@/constains'
import axios from '@/http'

export default defineComponent({
	name: 'GridSizePage',

	setup() {
		const products = ref([])
		const count = ref(0)
		const brandCategory = ref([])

		const brands = ref([])
		const categories = ref([])

		const sortTypes = [
			{
				name: 'по дате создания',
				key: 'sortCreated'
			},
			{
				name: 'по дате изменения',
				key: 'sortModified'
			}
		]

		const selectedSortType = ref({
			key: sortTypes[0].key,
			direction: 'DESC'
		})

		async function onSelectSort(sortTypeKey: string) {
			if (sortTypeKey === selectedSortType.value.key) {
				if (selectedSortType.value.direction === 'DESC') {
					selectedSortType.value.direction = 'ASC'
				} else if (selectedSortType.value.direction === 'ASC') {
					selectedSortType.value.direction = 'DESC'
				}

				handleFilterGridSize()
				return
			}
			selectedSortType.value = {
				key: sortTypeKey,
				direction: 'DESC'
			}
		}

		const genders = ref([
			{
				name: 'women',
				key: 0,
				label: 'Женщины'
			},
			{
				name: 'men',
				key: 1,
				label: 'Мужчины'
			},
			{
				name: 'girls',
				key: 2,
				label: 'Девочки'
			},
			{
				name: 'boys',
				key: 3,
				label: 'Мальчики'
			}
		])

		/* eslint-disable */
		const filterForm = reactive({
			brand: '',
			gender: '',
			category: ''
		})

		const handleFilterGridSize = async () => {
			let queryparams = ''

			if (filterForm.brand) {
				queryparams = queryparams + `brandId=${filterForm.brand}&`
			}

			if (filterForm.gender) {
				queryparams = queryparams + `gender=${filterForm.gender}&`
			}

			if (filterForm.category) {
				queryparams = queryparams + `categoryName=${filterForm.category}&`
			}

			const response = await axios.get(
				`/grid-size/getAll?${queryparams}${selectedSortType.value.key}=${selectedSortType.value.direction}`
			)

			products.value = response.data
		}

		/* eslint-disable */
		const getBrandById = (id: number) => {
			// @ts-ignore
			const brand = brandCategory.value.brands.find(i => i.id === id)

			return brand.name
		}

		const getGenderNameByIndex = (gender: number) => {
			let result = ''
			Object.values(localizeGender).forEach((i, index) => {
				if (index === gender) {
					result = i
				}
			})

			return result
		}

		const resetFilterGridSize = () => {
			location.reload()
		}

		const getCategoryById = (id: number) => {
			// @ts-ignore
			const category = brandCategory.value.categories.find(i => i.id === id)
			return category?.name || ''
		}

		onMounted(async () => {
			try {
				const response = await axios.get('/grid-size/getAll')
				products.value = response.data
				count.value = response.data.length
			} catch (e) {
				console.log('err: ', e)
			}

			const { data } = await axios.get('/grid-size/brand-category')

			brandCategory.value = data

			try {
				const response = await axios.get('/grid-size/brand-category')

				brands.value = response.data.brands
				categories.value = response.data.categories
			} catch (e) {
				console.log('err: ', e)
			}
		})

		return {
			brands,
			categories,
			filterForm,
			genders,
			localizeGender,
			count,
			products,
			brandCategory,
			getBrandById,
			getGenderNameByIndex,
			getCategoryById,
			handleFilterGridSize,
			resetFilterGridSize,
			sortTypes,
			selectedSortType,
			onSelectSort
		}
	}
})
